<template>
  <div>
    <b-form-group
      label-cols-md="1"
      label="Customer:"
      label-align-md="right"
      label-for="customer">
      <b-form-input id="customer" v-model="formData.applicationDetails.name" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="eMail:"
      label-align-md="right"
      label-for="email">
      <b-form-input id="email" v-model="formData.applicationDetails.email" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Free text:"
      label-align-md="right"
      label-for="freeText">
      <b-form-textarea id="freeText" v-model="formData.applicationDetails.freeText" rows="3" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Create Date:"
      label-align-md="right"
      label-for="createDate">
      <b-form-input id="createDate" v-model="formData.createDate" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Ad Title:"
      label-align-md="right"
      label-for="adTitle">
      <b-form-input id="email" v-model="formData.cpDetails.fragments.title" disabled />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Confirmed:"
      label-align-md="right"
      label-for="confirmed">
      <b-form-checkbox
        class="cols-md-4"
        id="confirmed"
        v-model="formData.confirmed" />
    </b-form-group>
    <b-form-group
      label-cols-md="1"
      label="Rejected:"
      label-align-md="right"
      label-for="rejected">
      <b-form-checkbox class="cols-md-4" id="sent" v-model="formData.rejected" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'gl-order',
  version: '0.0.1',
  props: {
    initialData: {
      type: Object,
      default: () => {}
    } // initialData
  }, // props
  data () {
    return {
      formData: this.initialData ? Object.assign({}, this.initialData) : {}
    }
  }, // data
  /* ----- vujs hooks ----- */
  mounted () {}, // mounted
  /* ----- vujs observers ----- */
  watch: {
    initialData: function (nv) {
      this.formData = Object.assign({}, this.initialData)
    } // initialData
  } // watch
}
</script>

<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}</style>
